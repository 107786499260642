<template>
  <common-cell-renderer-actions
    :actions="filteredActionsFromParent"
    @restore="restore()"
    @send="sendCampaign()"
    @view="viewRecord()"
    @clone="cloneRecord()"
    @edit="editRecord()"
    @delete="confirmDeleteRecord()">
  </common-cell-renderer-actions>
</template>

<script>
import CommonCellRendererActions from '@/views/modules/_components/cell-renderer/CommonCellRendererActions.vue';
import commonSingleCellRendererActions from '@/views/modules/_mixins/commonSingleCellRendererActions';

/**
 * Cell renderer to trash list
 *
 * @module views/modules/templates/TemplateCellRendererActions
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-event {void} restore - restore the item
 * @vue-event {void} sendCampaign - send campaign from template
 */
export default {
  components: {
    CommonCellRendererActions,
  },
  mixins: [commonSingleCellRendererActions],
  name: 'TemplateCellRendererActions',
  computed: {
  },
  methods: {
    restore() {
      this.params.context.componentParent.confirmRestoreRecord(this.params.node.data);
    },
    sendCampaign() {
      this.params.context.componentParent.sendCampaignFromTemplateFunction(this.params.node.data);
    },
  },
};
</script>
