<template>
  <div
    v-if="params.value"
    class="cursor-pointer"
    @click="showModal = true">
    {{ params.value }}

    <vs-popup
      :title="headerName"
      :active.sync="showModal"
      :class="{'z-index-above-modal': params && params.above}">
      <transition name="zoom-fade">
        <span>{{ params.value }}</span>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
/**
 * Component to show large text on popup for ag grid cells
 *
 * @module views/modules/components/cell-renderer/CellRendererLargeTextModal.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} showModal - indicate if show or no the modal
 * @vue-computed {void} headerName - header name to show in popup title
 */
export default {
  name: 'CellRendererLargeTextModal',
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    headerName() {
      return this.params
        ? this.params.colDef.headerName
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
  div {
    text-overflow: ellipsis;
    overflow-x: auto;
  }
</style>
