<template>
  <div>
    <import-excel-to-json
      :key="importComponentKey"
      v-if="showImportComponent"
      :target-headers="columnDefsToImport"
      :entity="entity"
      :on-save-import="importSMSTemplatesFromFile"
      @refresh="importComponentKey+=1"
      @imported="resetDataSourceAndSelection()"
      @close="showImportComponent=false"/>

    <div
      v-show="!showImportComponent"
      class="vx-card  p-6">
      <base-ag-grid-header
        :rows-selected-count="rowsSelectedCountWithAllSelection"
        :filters-count="filtersAppliedCount"
        :filters-match.sync="filtersMatch"
        @reset-selection="setSelectedRowsNone()"
        @reset-filters="resetFilters()">
        <template v-slot:header-left>
          <items-per-page
            :count="count"
            :currentPage="currentPage"
            :paginationPageSize="paginationPageSize"
            @changed-page-size="paginationSetPageSize">
          </items-per-page>

          <list-action-drop-down
            v-if="isAnyRowsSelected"
            :options="allMultipleActionOptions"
            class="ml-3"
            @export="exportData()"
            @delete="confirmDeleteRecords()"
            @restore="confirmRestoreRecordsForTrashWithAgGrid()">
          </list-action-drop-down>
        </template>

        <template v-slot:header-right>
          <vs-button
            v-if="!trash"
            color="primary"
            type="border"
            :size="headerButtonsSizeByResolution"
            icon-pack="feather"
            icon="icon-plus"
            class="mr-3"
            @click="newItem()">
            {{ $t("$General.AddNew") }}
          </vs-button>

          <vs-button
            v-if="!trash"
            color="primary"
            :size="headerButtonsSizeByResolution"
            icon-pack="feather"
            icon="icon-upload"
            class="mr-3"
            @click="showImportComponent=true">
            {{ $t("$General.Import") }}
          </vs-button>

          <vs-button
            radius
            color="primary"
            type="border"
            icon-pack="feather"
            icon="icon-refresh-cw"
            @click="resetDataSourceAndSelection()">
          </vs-button>
        </template>
      </base-ag-grid-header>

      <vs-alert
        :active.sync="selectionPageAlert"
        class="text-center"
        color="grey-dark"
        closable icon-pack="feather"
        close-icon="icon-x">
        {{ $t('$SharedByManyModules.SelectedAllPageInListMsg', {
        count: rowsSelectedCount,
        entity: $tc(`$Entities.${entity}`, rowsSelectedCount) }) }}
        <a
        href="#"
        @click.prevent="setSelectedRowsAll()">
          {{ $t('$SharedByManyModules.SelectAllInListMsg', {
          count: count,
          entity: $tc(`$Entities.${entity}`, count) }) }}
        </a>
      </vs-alert>

      <export-alert-info
        :entity="$tc(`$Entities.${entity}`, 2)"
        :exporting.sync="exportingList"
        :exported.sync="exportedList"
        :fileUrl="urlFileExported"
        :send-email="exportSendEmail"/>

      <ag-grid-vue
        :key="agGridKey"
        ref="agGridTable"
        :domLayout="domLayout"
        :style="gridStyle"
        :components="components"
        :frameworkComponents="frameworkComponents"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :column-types="columnTypes"
        :getRowNodeId="getRowNodeId"
        :autoParamsRefresh="true"
        rowSelection="multiple"
        :animateRows="true"
        :suppressRowClickSelection="true"
        rowModelType="infinite"
        :pagination="hasPagination"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="suppressPaginationPanel"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :maxConcurrentDatasourceRequests="maxConcurrentDatasourceRequests"
        :cacheBlockSize="cacheBlockSize"
        :maxBlocksInCache="maxBlocksInCache"
        @selection-changed="onSelectionChanged"
        @grid-ready="onGridReady"
        @model-updated="onModelUpdate"
        @grid-size-changed="onGridSizeChanged"
        @first-data-rendered="onFirstDataRendered"
        @row-clicked="onRowClicked"
        @row-double-clicked="onRowDoubleClicked"
        @filter-changed="onFilterChanged"
        @pagination-changed="onPaginationChanged">
      </ag-grid-vue>

      <vs-popup
        :title="titleModal"
        :active.sync="activeModalCreateOrEdit">
        <transition name="zoom-fade">
          <s-m-s-template-list-create-or-edit
            v-if="showCreateOrEditComponent"
            ref="createOrEdit"
            :entity="entity"
            :operation="operation"
            :model-payload="recordSelected"
            :actions="actionsOnCreateOrEdit"
            @action="onActionFromCreateOrEditForTemplate"
            @model-was-changed="(val) => this.createOrEditModelWasChanged=val"
            @saved="savedItemOnAgGridTable"
            @close="activeModalCreateOrEdit=false">
          </s-m-s-template-list-create-or-edit>
        </transition>
      </vs-popup>

      <vs-popup
        :title="exportModalTitle"
        :active.sync="activeModalToExport">
        <transition name="zoom-fade">
          <export-json-to-excel
            v-if="showExportComponent"
            :columns="getColumnsToExport"
            :data="rowsSelected"
            @close="activeModalToExport=false"
            @export="onExport">
          </export-json-to-excel>
        </transition>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
import { mapActions, mapMutations } from 'vuex';

// Mixins
import commonTemplateListCreateOrEdit from '@/views/modules/templates/mixins/commonTemplateListCreateOrEdit';

// Cell Renderer
import CellRendererActions from '@/views/modules/templates/TemplateCellRendererActions.vue';
import CellRendererLargeText from '@/views/modules/_components/cell-renderer/CellRendererLargeTextModal.vue';

// Custom Components
import ImportExcelToJson from '@/views/modules/_components/import-exce-to-json/ImportExcelToJson.vue';
import BaseAgGridHeader from '@/views/modules/_components/BaseAgGridHeader.vue';
import ItemsPerPage from '@/views/modules/_components/ItemsPerPage.vue';
import ExportJsonToExcel from '@/views/modules/_components/ExportJsonToExcel.vue';
import ListActionDropDown from '@/views/modules/_components/ListActionDropDown.vue';
import SMSTemplateListCreateOrEdit from '@/views/modules/templates/sms-template/SMSTemplateListCreateOrEdit.vue';
import AgGridSelectionHeader from '@/views/modules/_components/AgGridSelectionHeader';
import ExportAlertInfo from '@/views/modules/_components/ExportAlertInfo.vue';

/**
 * Component to list and manage sms templates
 *
 * @module views/modules/templates/SMSTemplateList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [entity='...'] - Name of the entity associated to list
 * @vue-data {Function} exportCollectionFunction - function to export the collection
 * @vue-data {Function | null} fetchAllCollectionFunction - function to fetch all collection
 * @vue-data {Function | null} fetchAllCollectionTrashFunction -
 * function to fetch all collection form trash
 * @vue-data {Function | null} deleteRecordFunction - function to delete one record
 * @vue-data {Function | null} [deleteRecordsFunction=null] - function to delete records
 * @vue-data {Function | null} restoreItemFunction - function to restore an item for trash
 * @vue-data {Function | null} restoreItemsFunction - function to restore many items from trash
 * @vue-data {Array.<Object>} columnDefs - Columns definition of ag grid to list
 * @vue-data {Function | null} sendCampaignFromTemplateFunction - function to call on send campaign
 * @vue-data {Object} components - Components to use to extend ag grid
 * @vue-data {Object} frameworkComponents - Framework components to extend ag grid
 * @vue-event {void} fetchAgGridData - fetch data to ag grid table
 * @vue-event {void} sendSMSCampaignFromTemplate - send a sms campaign from a sms template
 */
export default {
  name: 'SMSTemplateList',
  components: {
    BaseAgGridHeader,
    ExportJsonToExcel,
    ExportAlertInfo,
    ImportExcelToJson,
    ItemsPerPage,
    ListActionDropDown,
    SMSTemplateListCreateOrEdit,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererActions,
    // eslint-disable-next-line vue/no-unused-components
    CellRendererLargeText,
  },
  mixins: [commonTemplateListCreateOrEdit],
  data() {
    return {
      entity: this.$enums.Entity.SMS_TEMPLATE,
      exportCollectionFunction: (data) => this.exportSMSTemplates({
        exportInfo: data,
        trash: this.trash,
      }),
      fetchAllCollectionFunction: this.fetchAllSMSTemplates,
      fetchAllCollectionTrashFunction: this.fetchAllSMSTemplatesFromTrash,
      deleteRecordFunction: (id) => this.removeSMSTemplate({
        smsTemplateId: id,
        trash: this.trash,
      }),
      deleteRecordsFunction: (data) => this.removeSMSTemplates({ ...data, trash: this.trash }),
      restoreItemFunction: this.restoreSMSTemplate,
      restoreItemsFunction: this.restoreSMSTemplates,
      sendCampaignFromTemplateFunction: this.sendSMSCampaignFromTemplate,
      columnDefs: [
        {
          colId: 'name',
          headerName: this.$t('$General.Name'),
          field: 'name',
          filter: 'agTextColumnFilter',
          minWidth: 200,
          maxWidth: 960,
          checkboxSelection: true,
          headerComponentParams: { checkboxSelection: true },
          requireOnImport: true,
        },
        {
          colId: 'message',
          headerName: this.$tc('$General.Message'),
          field: 'message',
          filter: 'agTextColumnFilter',
          minWidth: 300,
          maxWidth: 1906,
          requireOnImport: true,
          cellRendererFramework: 'CellRendererLargeText',
        },
      ],
      components: {
        CellRendererActions,
        CellRendererLargeText,
      },
      frameworkComponents: {
        agColumnHeader: AgGridSelectionHeader,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchAllSMSTemplates: 'smsTemplate/fetchAllSMSTemplates',
      fetchAllSMSTemplatesFromTrash: 'smsTemplate/fetchAllSMSTemplatesFromTrash',
      importSMSTemplatesFromFile: 'smsTemplate/importFromFile',
      exportSMSTemplates: 'smsTemplate/exportFile',
      removeSMSTemplate: 'smsTemplate/removeSMSTemplate',
      removeSMSTemplates: 'smsTemplate/removeSMSTemplates',
      restoreSMSTemplate: 'smsTemplate/restoreSMSTemplate',
      restoreSMSTemplates: 'smsTemplate/restoreSMSTemplates',
    }),
    ...mapMutations({
      setTemplateToCampaign: 'smsCampaign/SET_FROM_SMS_TEMPLATE',
    }),
    async fetchAgGridData(params) {
      return this.fetchCollection(params);
    },
    sendSMSCampaignFromTemplate(smsTemplate) {
      this.setTemplateToCampaign(smsTemplate);
      this.$router.push({ name: 'sms-campaigns-create' });
    },
  },
};
</script>
